import React, { useState } from "react";
import { Row } from "../../commonalities";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import {
  SettingsIcon,
  LogsIcon,
  PlayIcon,
  DataCell,
  Cell,
  Spacing,
  IconHover,
  EditIcon,
  DeleteIcon,
} from "../../commonalities";
import { TileIcon } from "../../icons";
import DeleteJobPopUp from "./popups/DeleteJobPopUp";
import EditJobPopUp from "./popups/EditJobPopUp";
import JobInfoDropDown from "./JobInfoDropDown";
import ExecuteJobPopUp from "./popups/ExecuteJobPopUp";
import { NetworkController } from "../../utils";

const JobRow = ({
  rowKey,
  job,
  showDetail,
  setShowDetail,
  i,
  search
}) => {
  const [showDeletePopUp, setShowDeletePopUp] = useState({
    show: false,
    jobDetails: null,
  });
  const [showEditPopUp, setShowEditPopUp] = useState({
    show: false,
    jobDetails: null,
  });
  const [showExecutionPopUp, setShowExecutionPopUp] = useState({
    show: false,
    jobDetails: null,
  });
  const { t } = useTranslation();
  var st = DateTime.fromMillis(job.UploadTime * 1000);
  const [shrink, setShrink] = useState(false);
  // const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  // const [update, setUpdate] = useState(true);

  const setJobDetails = (details) => {
    // setUpdate(!update);
    window.location.reload();
  };
  const colors = {
    Verified: "green",
    Created: "orange",
  };
  
  const handleClick = (clickedAction, job) => {
    if (
      showDetail.show === clickedAction &&
      showDetail.job.Id === job.Id
    ) {
      setShrink(true);
      setShowDetail({ show: "", job: "" });
      setShrink(false);
    } else {
      setShowDetail({
        show: clickedAction,
        job: job,
      });
    }
  };

  const deleteJob = (JobId) => {
    setLoading(true);
    console.log("JobId in deleteJob: " + JobId);
    const data = {
      id: JobId,
    };
    NetworkController.jobs.delete(data, (res) => {
      if (res.status === 200) {
        console.log("Success in deleting the job: " + JobId);
        setLoading(false);
        window.location.reload();
      }
    });
  };

  const getHighlightedText = (text, highlight) => {
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts?.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? { backgroundColor: "#000", color: "#fff" }
                : {}
            }
          >
            {part}
          </span>
        ))}
      </span>
    );
  };

  return (
    <>
      <Spacing key={rowKey} mTopvh={"1.67"}>
        <Row width={"100%"}>
          <Cell vPadding={"1.67"} width={"2.413%"} />
          <DataCell
            vPadding={"1.67"}
            width={"4.039%"}
            value={i}
            tooltipValue={i}
          />
          <DataCell
            vPadding={"1.67"}
            width={"12.365%"}
            value={getHighlightedText(job.Id, search)}
            tooltipValue={job.Id}
          />
          <DataCell
            vPadding={"1.67"}
            width={"17.642%"}
            value={getHighlightedText(job.Name, search)}
            tooltipValue={job.Name}
          />
          <DataCell
            vPadding={"1.67"}
            width={"12.365%"}
            value={st.toLocaleString({ ...DateTime.DATETIME_MED_WITH_SECONDS, hour12: false, })}
            tooltipValue={st.toLocaleString({ ...DateTime.DATETIME_MED_WITH_SECONDS, hour12: false, })}
          />
          <DataCell
            vPadding={"1.67"}
            width={"12.903%"}
            color={colors[job.Status]}
            value={getHighlightedText(job.Status, search)}
          />
          <Cell vPadding={"0.84"} width={"10.376%"} textAlign={"left"} height={"fit-content"}>
            <Spacing setFitContent={true} margin={"auto"}>
              <IconHover
                onClick={() =>
                  setShowEditPopUp({
                    show: true,
                    jobDetails: job,
                  })
                }
                setPointer={true}
                setInline={true}
              >
                <EditIcon
                  width={"18px"}
                  color="black"
                  strokeWidth="0.8"
                />
              </IconHover>
              <IconHover
                onClick={() =>
                  setShowDeletePopUp({
                    show: true,
                    jobDetails: job,
                  })
                }
                setPointer={true}
                mLeft={"16px"}
                setInline={true}
              >
                <DeleteIcon width={"18px"} />
              </IconHover>
              <IconHover
                onClick={() => handleClick("jobInfo", job)}
                setPointer={true}
                mLeft={"16px"}
                setInline={true}
              >
                <TileIcon
                  width="18px"
                  color={
                    showDetail.show === "jobInfo" &&
                    showDetail.job.Id === job.Id &&
                    "orange"
                  }
                  fill={
                    showDetail.show === "jobInfo" &&
                    showDetail.job.Id === job.Id &&
                    true
                  }
                />
              </IconHover>
              <IconHover
                onClick={() =>
                  setShowExecutionPopUp({
                    show: true,
                    jobDetails: job,
                  })
                }
                setPointer={true}
                mLeft={"16px"}
                setInline={true}
              >
                <PlayIcon
                  width="18px"
                  color={
                    showExecutionPopUp.show &&
                    showExecutionPopUp.jobDetails.Id === job.Id &&
                    "orange"
                  }
                  fill={
                    showExecutionPopUp.show &&
                    showExecutionPopUp.jobDetails.Id === job.Id &&
                    true
                  }
                ></PlayIcon>
              </IconHover>
            </Spacing>
          </Cell>
          <Cell vPadding={"1.67"} width={"1.613%"} />
        </Row>
      </Spacing>

      {showEditPopUp.show && (
        <EditJobPopUp
          jobDetails={showEditPopUp.jobDetails}
          setShowEditPopUp={setShowEditPopUp}
          setJobDetails={setJobDetails}
        />
      )}

      {showDeletePopUp.show && (
        <DeleteJobPopUp
          setShowDeletePopUp={setShowDeletePopUp}
          deleteJob={deleteJob}
          jobDetails={showDeletePopUp.jobDetails}
        />
      )}

      {(showDetail.show === "jobInfo") && (showDetail.job.Id === job.Id) ?
        <JobInfoDropDown job={showDetail.job} shrink={shrink} />
        : null
      }

      {showExecutionPopUp.show && (
        <ExecuteJobPopUp
          setShowExecutionPopUp={setShowExecutionPopUp}
          job={showExecutionPopUp.jobDetails}
        />
      )}
    </>
  );
};
export default JobRow;
