import React from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import { SessionManager } from "./utils";
import { ConnectToAwsIoT } from "./AWS";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "styled-components";
import LuxonFnsUtils from "@date-io/luxon";
import { MuiThemeProvider } from "@material-ui/core";
import "i18next";
import { useStore } from "./store";
import { muiTheme, appTheme } from "./commonalities/Theme";
import { useTranslation } from "react-i18next";
import { Login, ControlRoom, CellDetail, ManageCells } from "./pages";
import  ManageJobs  from "./pages/manageJobs/ManageJobs";
import {
  AccountVerification,
  ForgotPassword,
  ManageUsers,
  RegisterUser as Register,
  ResetPassword,
  UserProfile,
  SessionExpiredPopUp,
} from "./commonalities";
import image from "./images/WSlogin.png";
import PageErrorImage from "./images/PageError.png";
import { PageError, UpdateSpinner } from "./commonalities";
import { useEffect, useState } from "react";
import * as Routes from "./routes/Routes";
import { AuthActions } from "./store";

const App = () => {
  const [showSessionExpiredPopUp, setShowSessionExpiredPopUp] = useState(false);
  const [authReady, setAuthReady] = useState(false);
  const { i18n, t } = useTranslation();
  SessionManager.t = t;
  const authActions = AuthActions();
  const [{ auth }] = useStore();
  SessionManager.language = i18n.language;
  SessionManager.showSessionExpiredPopUp = setShowSessionExpiredPopUp;

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      if (sessionStorage.getItem("UserData")) {
        authActions.setAuth(JSON.parse(sessionStorage.getItem("UserData")));
      }
    }
    setAuthReady(true);
    SessionManager.checkSessionValidity();

    return () => {
      clearInterval(SessionManager.sessionChecker);
    };
  }, []);

  useEffect(() => {
    if (!sessionStorage.getItem("accessKeyId")) {
      SessionManager.mqttclient = null;
    } else {
      ConnectToAwsIoT();
    }
  }, [auth]);

  const baseUrl = () => "/" + i18n.language;
  return (
    <ThemeProvider theme={appTheme}>
      <MuiThemeProvider theme={muiTheme}>
        <MuiPickersUtilsProvider utils={LuxonFnsUtils}>
          <div className="App" id="App">
            {showSessionExpiredPopUp && <SessionExpiredPopUp />}
            {!authReady ? (
              <UpdateSpinner />
            ) : (
              <Router basename={baseUrl()}>
                <Switch>
                  <Route
                    exact
                    path={Routes.FORGOT_PASSWORD}
                    component={ForgotPassword}
                  ></Route>
                  <Route exact path={Routes.REGISTER}>
                    <Register image={image} />
                  </Route>
                  <Route
                    exact
                    path={Routes.VERIFY}
                    component={AccountVerification}
                  ></Route>
                  <Route
                    exact
                    path={Routes.RESET_PASSWORD}
                    component={ResetPassword}
                  ></Route>
                  <Route exact path={Routes.PAGE_ERROR}>
                    <PageError image={PageErrorImage} />
                  </Route>
                  {!auth.user && (
                    <Switch>
                      <Route exact path={Routes.CONTROL_ROOM}>
                        <Login />
                      </Route>
                      <Route
                        exact
                        path={[
                          Routes.USER_PROFILE,
                          Routes.CELL_DETAILS,
                          Routes.MANAGE_CELLS,
                          Routes.MANAGE_USERS,
                        ]}
                      >
                        <PageError image={PageErrorImage} />
                      </Route>
                      <Route exact path="*">
                        <Redirect to={Routes.PAGE_ERROR} />
                      </Route>
                    </Switch>
                  )}
                  <Route
                    exact
                    path={Routes.CONTROL_ROOM}
                    component={ControlRoom}
                  ></Route>
                  <Route exact path={Routes.USER_PROFILE}>
                    <UserProfile redirection={Routes.CONTROL_ROOM} />
                  </Route>
                  <Route
                    exact
                    path={Routes.CELL_DETAILS}
                    component={CellDetail}
                  ></Route>
                  <Route
                    exact
                    path={Routes.MANAGE_CELLS}
                    component={ManageCells}
                  ></Route>
                  <Route
                    exact
                    path={Routes.MANAGE_JOBS}
                    component={ManageJobs}
                  ></Route>
                  <Route exact path={Routes.MANAGE_USERS}>
                    <ManageUsers redirection={Routes.CONTROL_ROOM} />
                  </Route>
                  <Route exact path="*">
                    <Redirect to={Routes.PAGE_ERROR} />
                  </Route>
                </Switch>
              </Router>
            )}
          </div>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

export default App;
