import axios from "axios";
require("dotenv").config();

var networkController = null;
var serverURL = process.env.REACT_APP_BACKEND_SERVER_URL;

class CNM {
  getUsage(data, callback) {
    axios
      .get(serverURL + "/cnm/getUsage?CellID=" + data, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
          authtype: "Odico",
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }
}

class Evalo {
  getUsage(data, callback) {
    axios
      .get(serverURL + "/evalo/getUsage?CellID=" + data, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
          authtype: "Odico",
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }
}

class Carpen {
  getUsage(data, callback) {
    axios
      .get(serverURL + "/carpen/getUsage?CellID=" + data, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
          authtype: "Odico",
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }
}

class Cells {
  get(data, callback) {
    axios
      .get(serverURL + "/cell/", {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
          authtype: "Odico",
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  getCell(data, callback) {
    axios
      .get(serverURL + "/cell/getCell/" + data.id, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
          authtype: "Odico",
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  getExecutionData(data, callback) {
    axios
      .get(serverURL + "/cell/getBagData/" + data.id, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
          authtype: "Odico",
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  getJobs(data, callback) {
    var query =
      "CellID=" +
      data.CellID +
      "&after=" +
      data.after +
      "&page=" +
      data.page +
      "&lastKey=" +
      data.page;
    if (data.before) {
      query = query + "&before=" + data.before;
    }
    axios
      .get(serverURL + "/cell/getProcessData?" + query, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
          authtype: "Odico",
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  getLogsData(data, callback) {
    axios
      .get(serverURL + "/cell/getProcessLogs/" + data.id, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
          authtype: "Odico",
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  getSettingsData(data, callback) {
    var query = "id=" + data.id;
    axios
      .get(serverURL + "/cell/getSettings?" + query, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
          authtype: "Odico",
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }
  delete(data, callback) {
    axios
      .post(serverURL + "/cell/delete", data, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }
  update(data, callback) {
    axios
      .post(serverURL + "/cell/update", data, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  sendExecutionDataToSetupPlanningScene(data, callback) {
    axios
      .post("http://localhost:3001/setup_planning_scene", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
        callback({ data: 0 });
      });
  }

  playExecutionBag(data, callback) {
    axios
      .post(
        "http://localhost:3001/play_execution_bag",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
        alert("Robot studio server is not available.");
      });
  }
}

class IAM {
  registerUser(data, callback) {
    axios
      .post(serverURL + "/registerUser/registerUser", data, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  verifyUser(data, callback) {
    axios
      .post(serverURL + "/verifyUser/verifyUser", data, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  approveUser(data, callback) {
    axios
      .post(serverURL + "/approveUser/approveUser", data, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  getAllUsers(callback) {
    axios
      .get(serverURL + "/getAllUsers/getAllUsers", {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  deleteUser(data, callback) {
    axios
      .post(serverURL + "/deleteUser/deleteUser", data, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  resendEmail(data, callback) {
    axios
    .post(serverURL + "/registerUser/registerUser", data, {
      headers: {
        "Content-Type": "application/json",
        token: sessionStorage.getItem("token"),
      },
    })
    .then((res) => {
      callback(res);
    })
    .catch(function (err) {
      console.log(err);
    });
  }

  loginUser(data, callback) {
    axios
      .post(serverURL + "/loginUser/loginUser", data, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  forgotPassword(data, callback) {
    axios
      .post(serverURL + "/forgotPassword/forgotPassword", data, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  resetAPI(data, callback) {
    axios
      .get(serverURL + "/resetPassword/resetPassword", {
        headers: {
          "Content-Type": "application/json",
          token: data.resetPasswordToken,
          email: data.email,
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  resetPassword(data, callback) {
    axios
      .post(serverURL + "/resetPassword/resetPassword", data, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  updateUser(data, callback) {
    axios
      .post(serverURL + "/updateUser/updateUser", data, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  changePassword(data, callback) {
    axios
      .post(serverURL + "/resetPassword/resetPassword", data, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }
}

class Jobs {
  get(data, callback) {
    var query =
    "after=" +
    data.after +
    "&page=" +
    data.page +
    "&lastKey=" +
    data.page;
    if (data.before) {
      query = query + "&before=" + data.before;
    }
    axios
      .get(serverURL + "/carpen/get?" + query, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
          authtype: "Odico",
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  upload(data, callback) {
    console.log("Job:" + data['job']);
    axios
      .post(serverURL + "/carpen/upload", data, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  update(data, callback) {
    console.log("Job ID:" + data['id']);
    axios
      .post(serverURL + "/carpen/update", data, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  delete(data, callback) {
    console.log("Job ID:" + data['id']);
    axios
      .post(serverURL + "/carpen/delete", data, {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }
}

class NetworkController {
  constructor() {
    this.cells = new Cells();
    this.cnm = new CNM();
    this.evalo = new Evalo();
    this.carpen = new Carpen();
    this.iam = new IAM();
    this.jobs = new Jobs();
  }
  getIoTAccess(data, callback) {
    axios
      .get(serverURL + "/cell/getIoTAccess", {
        headers: {
          "Content-Type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        res.status = res.data.status;
        callback(res);
      })
      .catch(function (err) {
        console.log(err);
      });
  }
}

if (networkController === null) {
  networkController = new NetworkController();
}

export default networkController;
