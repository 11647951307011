import { useTranslation } from "react-i18next";
import React from "react";
import {
  Spacing,
  PopUpHeading,
  PopUpText,
  PopUpTemplate,
  PopUpButtons,
} from "../../../commonalities";

const DeleteJobPopUp = ({
  jobDetails,
  deleteJob,
  setShowDeletePopUp,
}) => {
  const { t } = useTranslation();
  return (
    <PopUpTemplate setShowPopUp={setShowDeletePopUp}>
      <Spacing paddingvh={"6.67"} pRightvw={"6.25"} pLeftvw={"6.25"}>
        <PopUpHeading>{t("job-deleteHeading")}</PopUpHeading>
        <Spacing mTopvh={"3.70"} />
        <PopUpText color={"textGrey"}>{t("job-deleteMsg1")}</PopUpText>
        <Spacing
          mTopvh={"1.11"}
          width="max-content"
          mLeft={"auto"}
          mRight={"auto"}
        >
          <PopUpText font={"semibold"} setInline={true} color={"textDarkGrey"}>
            {jobDetails.Name}
          </PopUpText>
          <Spacing setInline={true} mRight={"5px"} />
          <PopUpText setInline={true} color={"textGrey"}>
            {t("job-deleteMsg2")}
          </PopUpText>
        </Spacing>
        <PopUpButtons
          bgColor="orange"
          confirmText="buttons-delete"
          onConfirmClick={() => {
            deleteJob(jobDetails.Id);
            setTimeout(() => {
              setShowDeletePopUp(false);
            }, 70);
          }}
          onCancelClick={() => {
            setShowDeletePopUp({ show: false, jobDetails: null });
          }}
        />
      </Spacing>
    </PopUpTemplate>
  );
};

export default DeleteJobPopUp;
