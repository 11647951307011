import axios from "axios";
import React, { useState } from "react";
import { Text, Button, InlineText, } from "../../commonalities";
import { useTranslation } from "react-i18next";
import { NetworkController } from "../../utils";

const FileUpload = ({ }) => {
    const { t } = useTranslation();
    const [selectedFile, setselectedFile] = useState("");
    // On file select (from the pop up)
    const onFileChange = (event) => {
        // Update the state
        setselectedFile(event.target.files[0]);
    };
    // On file upload (click the upload button)
    const onFileUpload = () => {
        //setLoading(true);
        if (selectedFile) {
            let min = 100000;
            let max = 999999;
            let random = Math.floor(Math.random() * (+max + 1 - +min)) + +min;
            const currentTime = Math.round((new Date()).getTime() / 1000)
            const identifier = currentTime.toString() + random.toString()
            const data = {
                id: identifier,
                author: 'Asbjorn',
                job: text,
                name: selectedFile.name,
                status: 'Verified',
                type: 'Carpen',
                uploadTime: currentTime
            };
            NetworkController.jobs.upload(data, (res) => {
                if (res.status === 200) {
                    // setLoading(false);
                    window.location.reload();
                }
            });
        } else {
            console.log("No file chosen!")
        }
    };

    let fileReader;
    const [text, setText] = useState();

    const cleanContent = string => {
        string = string.replace(/^\s*[\r\n]/gm, "");
        let array = string.split(new RegExp(/[\r\n]/gm));
        console.log(array);
        array.splice(0, 3);
        array.splice(-3);
        return array.join("\n");
    };

    const handleFileRead = e => {
        let content = fileReader.result;
        //Keeping it in case we may need it for the future.
        //content = cleanContent(content);
        setText(content);
    };

    // File content to be displayed after
    // file upload is complete
    const fileData = () => {
        if (selectedFile) {
            fileReader = new FileReader();
            fileReader.onloadend = handleFileRead;
            fileReader.readAsText(selectedFile);
            return (
                <div>
                    <InlineText fontSize="sm">
                        File Details:
                    </InlineText>
                    <InlineText fontSize="sm">
                        File Name: {selectedFile.name}
                    </InlineText>
                    <InlineText fontSize="sm">
                        File Type: {selectedFile.type}
                    </InlineText>
                    <InlineText fontSize="sm">
                        Last Modified:
                        {selectedFile.lastModifiedDate.toDateString()}
                    </InlineText>
                    <InlineText fontSize="sm">
                        Contents:
                        {text}
                    </InlineText>
                </div>
            );
        } else {
            return (
                <div>
                    <InlineText fontSize="sm">
                        Choose before Pressing the Upload button
                    </InlineText>
                </div>
            );
        }
    };
    if (selectedFile) {
        return (
            <div>
                <Text height="auto" fontSize={"lg"} font={"medium"}>
                    {t("uploadFile")}
                </Text>
                <div>
                    <input type="file" onChange={onFileChange} />
                    <Button onClick={onFileUpload} disabled={!selectedFile}>{t("buttons-upload")}</Button>
                </div>
                {fileData()}
            </div>
        );
    } else {
        return (
            <div>
                <Text height="auto" fontSize={"lg"} font={"medium"}>
                    {t("uploadFile")}
                </Text>
                <div>
                    <input type="file" onChange={onFileChange} />
                </div>
                {fileData()}
            </div>
        );
    }
};

export default FileUpload;