import { useHistory } from "react-router-dom";
import React from "react";
import { useStore } from "../../store";
import { useState, useEffect, useRef } from "react";
import {
  PageHeading,
  PageBody,
  Row,
  TableContainer,
  HeaderCell,
  Cell,
  TablePagination,
  Loader,
} from "../../commonalities";
import { Header as SiteHeader } from "../../../src";
import * as Routes from "../../routes/Routes";
import { useTranslation } from "react-i18next";
import { NetworkController, SessionManager } from "../../utils";
import FileUpload from "./FileUpload";
import { DateTime } from "luxon";
import JobRow from "./JobRow";
import MiddleHeader from "./MiddleHeader";

const ManageJobs = ({ }) => {
  const [fromDate, setFromDate] = useState(
    DateTime.local().minus({ days: 31 })
  );
  const [toDate, setToDate] = useState(DateTime.local());
  const toDateRealTime = useRef(true);
  const [refresh, setRefresh] = useState(false);
  const [showDetail, setShowDetail] = useState({ job: "", show: "" });
  const colors = ["red", "green", "orange", "textDarkGrey"];
  const history = useHistory();
  const { t } = useTranslation();
  const [{ auth }] = useStore();
  const [sort, setSort] = useState("Name");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(true);
  const [isAscending, setisAscending] = useState(1);
  const [pageDetails, setPageDetails] = useState({ current: 1, total: 1 });
  const displayCount = useRef(0);
  const [jobs, setJobs] = useState([]);
  const [displayJobs, setDisplayJobs] = useState([]);
  const setSorting = (sortBy) => {
    if (sort === sortBy) {
      setisAscending(-isAscending);
    } else {
      setisAscending(1);
    }
    setSort(sortBy);
  };
  
  useEffect(() => {
    setDisplayJobs(jobs.filter((job) => {
      if (
        (job.Name)
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        job.Id.toLowerCase().includes(search.toLowerCase()) ||
        job.Status.toLowerCase().includes(search.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    })
      .sort((a, b) => {
        if (
          a[sort]?.toString().toLowerCase() < b[sort]?.toString().toLowerCase()
        ) {
          return -isAscending;
        } else {
          return isAscending;
        }
      }));
  }, [search, isAscending, sort, pageDetails]);

  displayCount.current = 0;

  return (
    <>
      <SiteHeader search={search} setSearch={setSearch} />
      {loading && <Loader />}
      <PageBody>
        <PageHeading
          redirection={Routes.CONTROL_ROOM}
          heading="manageJobsTitle"
        />
        <TableContainer
          minWidth="960px"
          widthvw="77.53"
          setMLeft={true}
          setMBottom={true}
          mRightvw="3.33"
        >
          <FileUpload />
          <br />
          <MiddleHeader
            setFilteredJobs={setJobs}
            setFilteredDisplayJobs={setDisplayJobs}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            toDateRealTime={toDateRealTime}
            refresh={refresh}
            setRefresh={setRefresh}
            setLoading={setLoading}
          />
          <br />
          <Row width={"100%"}>
            <Cell vPadding={"1.67"} width={"2.413%"} />
            <Cell fontSize={"xs"} vPadding={"1.67"} width={"4.039%"} />
            <HeaderCell
              onClick={() => setSorting("Id")}
              width={"12.365%"}
              heading={"JobId"}
              isAscending={isAscending}
              isSelected={sort === "Id"}
            />
            <HeaderCell
              onClick={() => setSorting("Name")}
              isAscending={isAscending}
              isSelected={sort === "Name"}
              heading={"jobName"}
              width={"17.642%"}
            />
            <HeaderCell
              onClick={() => setSorting("UploadTime")}
              isAscending={isAscending}
              isSelected={sort === "UploadTime"}
              heading={"uploadTime"}
              width={"12.365%"}
            />
            <HeaderCell
              onClick={() => setSorting("Status")}
              isAscending={isAscending}
              isSelected={sort === "Status"}
              heading={"status"}
              width={"12.903%"}
            />
            <Cell fontSize={"xs"} vPadding={"1.67"} width={"5.376%"} />
            <Cell vPadding={"1.67"} width={"1.613%"} />
          </Row>
          {displayJobs
            .slice((pageDetails.current - 1) * 10, pageDetails.current * 10)
            .map((job, index) => {
              //console.log("job.Id: " + job.Id);
              var i = (pageDetails.current - 1) * 10 + (index + 1);
              displayCount.current += 1;
              return (
                <JobRow
                  key={job.Id}
                  job={job}
                  setShowDetail={setShowDetail}
                  showDetail={showDetail}
                  i={i}
                  search={search}
                />
              );
            })}
          <TablePagination
            displayCount={displayCount.current}
            totalItems={
              displayJobs
                ? displayJobs.length
                : 0
            }
            setCurrentPage={(value) => {
              setPageDetails({ ...pageDetails, current: value });
            }}
            currentPage={pageDetails.current}
            totalPages={Math.ceil(displayJobs.length / 10)}
          />
        </TableContainer>
      </PageBody>
    </>
  );
};
export default ManageJobs;
