import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NetworkController, SessionManager } from "../../../utils";
import {
  Button,
  Spacing,
  PopUpHeading,
  PopUpTemplate,
  Loader,
  InputWithError,
  Text,
  FormColumn,
} from "../../../commonalities";

const EditJobPopUp = ({ jobDetails, setShowEditPopUp, setJobDetails }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    name: jobDetails.Name,
    status: jobDetails.Status,
  });
  const [errors, setErrors] = useState({
    name: "",
    status: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const updateClicked = () => {
    var regex = /^[A-Za-z0-9_-]+$/;
    var isValid = regex.test(values.name);
    var isStatusValid = regex.test(values.status);
    var isError = false;
    var nameError = "";
    var statusError = "";
    if (!isValid) {
      nameError = t("job-invalidInput");
      isError = true;
    }
    if (!isStatusValid) {
      statusError = t("job-invalidInput");
      isError = true;
    }
    if (values.status === "") {
      statusError = t("login-requiredField");
      isError = true;
    }
    if (values.name === "") {
      nameError = t("login-requiredField");
      isError = true;
    }

    setErrors({
      name: nameError,
      status: statusError,
    });
    if (isError) {
      return;
    }
    if (
      values.name !== jobDetails.Name ||
      values.owner !== jobDetails.Owner
    ) {
      var jobData = {
        name: values.name,
        status: values.status,
        id: jobDetails.Id,
      };
      setLoading(true);
      NetworkController.jobs.update(jobData, (res) => {
        setLoading(false);
        if (res.status === 200) {
          setShowEditPopUp(false);
          setJobDetails({ jobData });
        }
      });
    } else {
      setShowEditPopUp(false);
    }
  };
  return (
    <>
      {loading && <Loader />}
      <PopUpTemplate
        setShowPopUp={setShowEditPopUp}
        width="400px"
        minWidth={"15vw"}
      >
        <Spacing paddingvh="6.67">
          <FormColumn alignItems="left" height="auto">
            <PopUpHeading
              top={"none"}
              fontSize={"xl"}
              textAlign={"left"}
              font="regular"
            >
              {t("job-editJob")}
            </PopUpHeading>
            <Spacing mTopvh={"4.17"} />

            <InputWithError
              onChange={handleChange}
              value={values.name}
              helperText={errors.name}
              error={errors.name !== ""}
              name={"name"}
              width={"100%"}
              label={t("jobName")}
              type={"text"}
            />

            <Text fontSize="xxs" mBottomvh="0.74" color="textGrey">
              {t("type")}
            </Text>
            <Text fontSize="sm">{jobDetails.Type}</Text>

            <Spacing mTopvh={"3.24"}>
              <Text
                height="auto"
                fontSize="xxs"
                mBottomvh="0.74"
                color="textGrey"
              >
                {t("author")}
              </Text>
              <Text height="auto" fontSize="sm">
                {jobDetails.Author}
              </Text>
            </Spacing>

            <Spacing mBottomvh={"2.69"} mTopvh={"3.24"}>
              <InputWithError
                onChange={handleChange}
                value={values.status}
                helperText={errors.status}
                error={errors.status !== ""}
                name={"status"}
                width={"100%"}
                label={t("status")}
                type={"text"}
              />
            </Spacing>

            <Button onClick={updateClicked} width="fit-content">
              {t("buttons-update")}
            </Button>
          </FormColumn>
        </Spacing>
      </PopUpTemplate>
    </>
  );
};

export default EditJobPopUp;
