import React from "react";
import { Spacing, InlineText } from "../../commonalities";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";

const ComputeMiddleHeader = ({ data }) => {
  const { t } = useTranslation();
  const [processData, setProcessData] = useState({
    totalVerifiedJobs: 0,
    totalCreatedJobs: 0,
    bladesChanged: 0,
    successfulJobs: 0,
    preemptedJobs: 0,
    erroneousJobs: 0,
  });

  useEffect(() => {
    var verifiedJobsCount = 0;
    var createdJobsCount = 0;
    var bladesChanged = 0;
    var successfulJobs = 0;
    var preemptedJobs = 0;
    var erroneousJobs = 0;

    data.forEach((item) => {
      if (item.Status === "Verified") {
        verifiedJobsCount += 1;
      } else if (item.Status === "Created") {
        createdJobsCount += 1;
      }
    });
    setProcessData({
      totalVerifiedJobs: verifiedJobsCount,
      totalCreatedJobs: createdJobsCount,
      bladesChanged: bladesChanged,
      successfulJobs: successfulJobs,
      preemptedJobs: preemptedJobs,
      erroneousJobs: erroneousJobs,
    });
  }, [data]);

  return (
    <>
      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.totalVerifiedJobs ? processData.totalVerifiedJobs : "0"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("verifiedJobs")}
        </InlineText>
      </Spacing>
      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.totalCreatedJobs ? processData.totalCreatedJobs : "0"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("createdJobs")}
        </InlineText>
      </Spacing>
      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.bladesChanged ? processData.bladesChanged : "0"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("bladeChanges")}
        </InlineText>
      </Spacing>
      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.successfulJobs ? processData.successfulJobs : "0"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("successfulJobs")}
        </InlineText>
      </Spacing>
      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.preemptedJobs ? processData.preemptedJobs : "0"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("preemptedJobs")}
        </InlineText>
      </Spacing>
      <Spacing width="fit-content">
        <InlineText color="black" font={"semibold"} fontSize={"lg"}>
          {processData.erroneousJobs ? processData.erroneousJobs : "0"}
        </InlineText>
        <InlineText color="black" fontSize={"xs"}>
          {t("errors")}
        </InlineText>
      </Spacing>
    </>
  );
};

export default ComputeMiddleHeader;
