import React, { useState } from "react";
import { NetworkController } from "../../../utils";
import { useEffect } from "react";
import {
  Spacing,
  PopUpHeading,
  PopUpText,
  PopUpTemplate,
  TextButton,
  Button,
} from "../../../commonalities";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { SessionManager } from "../../../utils";


const ExecuteJobPopUp = ({ setShowExecutionPopUp, job }) => {
  const { t } = useTranslation();
  const [stageMessage, setStageMessage] = useState({
    message1: "",
    message2: "",
    stage: "",
  });

  const handlePlay = () => {
    if (SessionManager.mqttClient) {
      SessionManager.mqttClient.publish(
        "sculptor/" + "Darwin" + "/controller/job",
        JSON.stringify({ job: job.Details })
      );
      SessionManager.realtimeLogsCallback = (logs) => {

      };
    }
    return () => {
      SessionManager.realtimeLogsCallback = null;
    };
  };

  const exitPopUp = () => {
    setTimeout(() => {
      setShowExecutionPopUp(false);
    }, 70);
  };

  useEffect(() => {
    setStageMessage({
      message1: "",
      message2: "",
      stage: "playScene",
    });


  }, []);

  return (
    <PopUpTemplate setShowPopUp={setShowExecutionPopUp} height="fit-content">
      <Spacing paddingvh={"6.67"} pRightvw={"6.25"} pLeftvw={"6.25"}>
        <PopUpHeading>{t("executeJob")}</PopUpHeading>
        {stageMessage.stage === "playScene" && (
          <>
            <Spacing
              width="max-content"
              mTopvh={"2.96"}
              mLeft={"auto"}
              mRight={"auto"}
            >
              <Button fontSize={"md"} onClick={handlePlay}>
                {t("buttons-play")}
              </Button>
            </Spacing>
            <Spacing
              width="max-content"
              mTopvh={"0.74"}
              mLeft={"auto"}
              mRight={"auto"}
            >
              <TextButton onClick={exitPopUp}>{t("buttons-cancel")}</TextButton>
            </Spacing>
          </>
        )}
        <Spacing mBottom={"xl"} />
      </Spacing>
    </PopUpTemplate>
  );
};



export default ExecuteJobPopUp;
