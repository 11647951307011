import React from "react";
import { PropertyCard } from "../../styles";
import { useTranslation } from "react-i18next";
import {
  FormRow,
  Spacing,
  ScrollingContainer,
  Text,
  InlineText,
} from "../../commonalities";

const JobInfoDropDown = ({ shrink, job }) => {
  const { t } = useTranslation();
  return (
    <Spacing mTopvw={"0.84"}>
      <ScrollingContainer
        height={"275px"}
        mLeft="0px"
        width="100%"
        overflowX="visible"
        setFlexColumn={true}
      >
        <Spacing
          pTopvw={"1.67"}
          pRightvw={"1.25"}
          pBottomvw={"2.5"}
          pLeftvw={"2.5"}
        >
          <Spacing mTop="10px" mRight="auto" hideYOverflow={true}>
            <Text font={"semibold"} fontSize="xs">
              {t("jobInformation")}
            </Text>
          </Spacing>
          <Spacing mTopvh="1.48" height="fit-content" hideYOverflow={true}>
            <FormRow>
              <FormRow width={"24.806%"}>
                <InlineText fontSize="sm">
                  {job.Details}
                </InlineText>
              </FormRow>
            </FormRow>
            <Spacing mTopvh={"1.02"} />
          </Spacing>
        </Spacing>
      </ScrollingContainer>
    </Spacing>
  );
};

export default JobInfoDropDown;
